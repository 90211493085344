import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { below } from '../utilities/breakpoints';
import { colorPrimary } from '../utilities/colors';
import defaultImg from '../static/default.jpg';
import sent_img from '../static/sent_img.png';
import unsent_img from '../static/unsent1_img.png';
import Lightbox from '../components/Lightbox';

const Gifts = ({ gifts, eventId }) => {
  const [lightbox, setLightbox] = useState(false);
  const [images, setImages] = useState([]);

  const displayLightbox = (mainImage, secondaryImages) => {
    let imgArray;
    if (secondaryImages && secondaryImages.length) {
      const otherImages = secondaryImages.map(url => ({ src: url, alt: '' }));
      imgArray = [{ src: mainImage, alt: '' }, ...otherImages];
    } else if (!mainImage) {
      imgArray = [{ src: defaultImg, alt: '' }];
    } else {
      imgArray = [{ src: mainImage, alt: '' }];
    }

    setLightbox(!lightbox);
    setImages(imgArray);
  };

  if (!gifts) return 'Loading...';

  return (
    <Container>
      <CardSection>
        {gifts &&
          gifts.map(gift => {
            const { title, mainImage, secondaryImages, giver, id, thankYouSent } = gift;
  	    const sentIndicator = thankYouSent ? sent_img : unsent_img;
            return (
              <Card style={{ width: '18rem' }} key={id}>
                <Card.Img
                  variant="top"
                  src={mainImage || defaultImg}
                  height="300"
                  onClick={() => displayLightbox(mainImage, secondaryImages)}
                  />
 		<div style={{ position: 'absolute'}}>
		<img style={{height:40,top:260,left:0}} src={sentIndicator} alt="Thank You Sent Indicator"/>
 		</div>
                 <Card.Body>
                 <Card.Title>{title}</Card.Title>
                  <Card.Text>From: {giver}</Card.Text>
                  <Link to={`/gifts/${eventId}/gift/${id}`}>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </Link>
                 </Card.Body>

              </Card>
           );
          })}
      </CardSection>
    </Container>
  );
};

const Container = styled.section`
  padding: 80px 0 0 0;

  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
`;

const CardSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 40px;
  justify-items: center;
  align-items: start;

  .h5 {
    color: ${colorPrimary};
  }

  .card {
    height: 500px;
    text-align: center;

    img {
      object-fit: cover;
      position: relative;
    }

  }

  ${below.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${below.phone`
    grid-template-columns: 1fr;
  `}

  .card__thank-you {
    color: ${colorPrimary};
  }
`;

export default Gifts;
