import React from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from '../elements/buttons';

const FacilityProfile = ({ values, user, handleInput, handleSubmit }) => {
  const {
    name,
    email,
    firstName,
    lastName,
    address,
    city,
    state,
    phone,
    website,
    zipCode,
  } = user;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Facility Name</Form.Label>
        <Form.Control
          onChange={handleInput}
          type="text"
          name="name"
          placeholder={name}
          value={values['name']}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          onChange={handleInput}
          type="email"
          name="email"
          placeholder={email}
          value={values['email']}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          onChange={handleInput}
          type="text"
          name="firstName"
          placeholder={firstName}
          value={values['firstName'] || firstName}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          onChange={handleInput}
          type="text"
          name="lastName"
          placeholder={lastName}
          value={values['lastName'] || lastName}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Phone</Form.Label>
        <Form.Control
          onChange={handleInput}
          type="phone"
          name="phone"
          placeholder={phone}
          value={values['phone'] || phone}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Address</Form.Label>
        <Form.Control
          onChange={handleInput}
          type="text"
          name="address"
          placeholder={address}
          value={values['address'] || address}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>City</Form.Label>
        <Form.Control
          onChange={handleInput}
          type="text"
          name="city"
          placeholder={city}
          value={values['city'] || city}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>State</Form.Label>
        <Form.Control
          onChange={handleInput}
          type="text"
          name="state"
          placeholder={state}
          value={values['state'] || state}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Zip Code</Form.Label>
        <Form.Control
          onChange={handleInput}
          type="text"
          name="zipCode"
          placeholder={zipCode}
          value={values['zipCode'] || zipCode}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Website</Form.Label>
        <Form.Control
          onChange={handleInput}
          type="text"
          name="website"
          placeholder={website}
          value={values['website'] || website}
        />
      </Form.Group>
      <Button type="submit" variant="primary">
        Submit
      </Button>
    </Form>
  );
};

export default FacilityProfile;
