import React from 'react';
import styled from 'styled-components';

import { Hero, HeroContent } from '../components/Hero';

import Layout from '../containers/Layout';
import FAQ from '../components/FAQ';

const FAQs = () => {
  return (
    <Layout>
      <Hero small>
        <HeroContent small>
          <h1>Frequently Asked Questions</h1>
        </HeroContent>
      </Hero>

      <FAQSection>
        <FAQ
          question="Is Doorbadge easy to use?"
          answer="Yes, Easy can be a relative term of course, so when we say easy, we mean if you can take a picture with your
            phone or tablet, you can use our product. Doorbadge is simple for even the least technology savvy person.
            Doorbadge was developed with the help of funeral directors to ensure we make the platform as easy for homes
            and families alike."
        />

        <FAQ
          question="How is my subscription billed?"
          answer="Upon signing up, your account will be charged monthly. We do not charge per funeral service, so it is a flat
            fee each month."
        />
        <FAQ
          question="How do I cancel?"
          answer="Simply write info@doorbadge.com or give us a call. We will reply within 48 hours."
        />
        <FAQ
          question="Do I have training support?"
          answer="Absolutely. We are here to help. We can do conference calls, WebEx meetings, or visit in person to ensure
            you and your staff get the most out of our product."
        />
        <FAQ
          question="Are their any contracts? "
          answer="Nope, cancel at any time. We don’t think you will, but if you need to, you can. "
        />

        <FAQ
          question="Are you on android as well as Apple? "
          answer="Currently we are only on Apple devices. But don’t worry, if you want to sign up, we will work to ensure you
            have the equipment you need to serve your families."
        />

        <FAQ
          question="Where are we located? "
          answer="We are located in beautiful Franklin, TN. Our address is 1550 West McEwen Dr. STE 300-28, Franklin, TN 37067"
        />

        <FAQ
          question="Do you offer free trails? "
          answer="Certainly. The last thing we want is a family not to get help because of reluctance to take on a new
            process. Let’s discuss!"
        />

        <FAQ
          question="How do I give the families their information?"
          answer="We have that covered for you. When you create an event, you are prompted to put in a email address. We will
            use that address and send the families the information to log in."
        />

        <FAQ
          question="Are the thank you cards ecards or physical cards?"
          answer="The thank you cards are physical cards. Your families can send in bulk or one at a time. They can pick from
            standard cards or make their own. When the card is ordered it is sent directly with postage paid."
        />

        <FAQ
          question="How much are the thank you cards through Doorbadge?"
          answer="That depends on the level of customization of course, but typically they are around $1.50 each including
            postage."
        />
      </FAQSection>
    </Layout>
  );
};

const FAQSection = styled.div`
  margin: 30px 0;

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  /* table {
  border-collapse: collapse;
  border-spacing: 0;
} */

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .cf:before,
  .cf:after {
    content: ' ';
    /* 1 */
    display: table;
    /* 2 */
  }

  .cf:after {
    clear: both;
  }

  /**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
  .cf {
    *zoom: 1;
  }

  .Collapsible {
    background-color: white;
    max-width: 700px;
    margin: 20px auto;
    padding: 0 20px;
  }

  .Collapsible__contentInner {
    padding: 10px;
    border: 1px solid #ebebeb;
    border-top: 0;
  }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
  }
  .Collapsible__contentInner p:last-child {
    margin-bottom: 0;
  }

  .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: #333333;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    background: #00ac9d;
    color: white;
  }
  .Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
  }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }

  .CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
  }

  .CustomTriggerCSS--open {
    background-color: darkslateblue;
  }

  .Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #cbb700;
    color: black;
  }
`;

export default FAQs;
