import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Profile from './pages/Profile';
import Events from './pages/Events';
import Facilities from './pages/Facilities';
import LovedOnes from './pages/LovedOnes';
import MemoryBookAuth from './pages/MemoryBookAuth';
import MemoryBook from './pages/MemoryBook';
import Contact from './pages/Contact';
import EventOverview from './pages/EventOverview';
import EventInfo from './pages/EventInfo';
import Gift from './pages/Gift';
import Index from './pages/Index';
import FAQs from './pages/FAQs';
import ResetPassword from './pages/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import Checkout from './pages/Checkout';
import PrivacyPolicy from './pages/PrivacyPolicy';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Index} ></Route>
          <Route path="/checkout" component={Checkout} ></Route>
	  <Route path="/privacy" component={PrivacyPolicy} ></Route>
          <Route path="/sign-up" component={SignUp} ></Route>
          <Route path="/sign-in" component={SignIn} ></Route>
          <Route path="/faq" component={FAQs} ></Route>
          <Route path="/reset-password" component={ResetPassword} ></Route>
          <Route path="/facilities" component={Facilities} ></Route>
          <Route path="/loved-ones" component={LovedOnes} ></Route>
          <Route path="/memory-book" component={MemoryBookAuth} ></Route>
          <Route path="/memory-book/:eventCode" component={MemoryBook} ></Route>
          <Route path="/contact" component={Contact} ></Route>
          <ProtectedRoute path="/profile" component={Profile} ></ProtectedRoute>
          <ProtectedRoute path="/events" component={Events} ></ProtectedRoute>
          <ProtectedRoute path="/event/:eventCode" component={EventOverview} ></ProtectedRoute>
          <ProtectedRoute path="/eventInfo/:eventId" component={EventInfo} ></ProtectedRoute>
          <ProtectedRoute path="/gifts/:eventId/gift/:giftId" component={Gift} ></ProtectedRoute>
        </Switch>
      </Router>
      <ToastContainer autoClose={3000} />
    </div>
  );
};

export default App;
