import styled from 'styled-components';

export const ButtonContainer = styled.div`
  button:not(:last-child) {
    margin-right: 5px;
  }

  a:not(:last-child) {
    margin-right: 5px;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
`;