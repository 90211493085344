import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../containers/Layout';
import Form from 'react-bootstrap/Form';
import { Button } from '../elements/buttons';
import { Showcase } from '../elements/banners';
import { Container } from '../elements/containers';

const MemoryBookAuth = () => {
  const [eventCode, setEventCode] = useState('');

  const handleInput = async e => {
    setEventCode(e.target.value);
  };

  const handleSubmit = async () => {
    setEventCode('');
    return <Redirect to={`/event/${eventCode}`} />;
  };

  return (
    <Layout>
      <Showcase>
        <h1>Enter Event Code</h1>
      </Showcase>
      <Container>
        <SignUpContent>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Event Code</Form.Label>
              <Form.Control
                onChange={handleInput}
                type="text"
                placeholder="Enter event code"
                value={eventCode}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </SignUpContent>
      </Container>
    </Layout>
  );
};

const SignUpContent = styled.div`
  width: 60%;
  margin: 0 auto;

  h1 {
    margin-bottom: 20px;
  }
`;

export default MemoryBookAuth;
