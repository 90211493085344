import React from 'react';
import styled from 'styled-components';
import { Link } from '@react-navigation/native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import whyDoorbadge from '../static/why-doorbadge.jpg';
import familyBenefits from '../static/family-benefits.jpg';
import { Button } from '../elements/buttons';
import { Showcase } from '../elements/banners';
import { below } from '../utilities/breakpoints';
import { colorPrimary } from '../utilities/colors';
import Layout from '../containers/Layout';

const Facilities = () => {
  return (
    <Layout>
      <Showcase>
        <h1>Thank You Reimagined</h1>
      </Showcase>
      <HalfLayout>
        <div>
          <h2>Facility Benefits</h2>
          <p>
            As a member with DoorBadge, you will get full access to the gift
            service platform.
          </p>
          <p>
            Give your valued customer the ability to see whats gifts were
            given,who gave them and visit the digital memorybook, and see what
            friends and families shared their special memories.
          </p>
          <p>You and your customers will get free access to the mobile app.</p>
        </div>
        <div className="HalfLayout__image">
          <img src={whyDoorbadge} alt="Two people shaking hands" width="450" />
        </div>
      </HalfLayout>
      <HalfLayout>
        <div>
          <img
            src={familyBenefits}
            alt="Two people shaking hands"
            width="450"
          />
        </div>
        <div>
          <h2>Family Benefits</h2>
          <p>
            Your customers will have digital options to keep track of the gifts
            that were given and who gave them.
          </p>
          <p>
            Digital access to the memory book for your customers and their
            guests.
          </p>
          <p>
            Let your customers focus on the service and their loved ones
            relieving them of the worry of remembering the gifts that each guest
            have.
          </p>
        </div>
      </HalfLayout>
      <CheckboxSection>
        <Col3>
          <h2>Ready To Get Started?</h2>
          <CheckboxWrap>
            <FontAwesomeIcon icon={faCheckSquare} color={colorPrimary} />
            <p>No Hidden Fees</p>
          </CheckboxWrap>
          <CheckboxWrap>
            <FontAwesomeIcon icon={faCheckSquare} color={colorPrimary} />
            <p>No Contracts</p>
          </CheckboxWrap>
          <CheckboxWrap>
            <FontAwesomeIcon icon={faCheckSquare} color={colorPrimary} />
            <p>Easy To Use</p>
          </CheckboxWrap>
          <h3>
            Click on the Sign Up button at the top of this page and get started
          </h3>
        </Col3>
      </CheckboxSection>
    </Layout>
  );
};

const HalfLayout = styled.section`
  display: grid;
  grid-template-columns: repeat(2, [col-start] 1fr) [col-end];
  justify-items: center;
  align-items: center;
  padding: 0 0 80px 0;
  width: 90%;
  margin: 0 auto;

  ${below.tablet`
    grid-template-columns: 1fr;
    text-align: center;

    .HalfLayout__image {
      grid-row: 1 / -1;
    }
  `}

  &:nth-child(2) {
    border-bottom: 1px solid #e7e7e7;
  }

  img {
    height: 350px;
    object-fit: cover;
    max-width: 100%;

    ${below.tablet`
      margin-bottom: 40px;
    `}
  }
`;

const Col3 = styled.section`
  display: grid;
  grid-template-columns: repeat(3, [col-start] 1fr) [col-end];
  grid-column-gap: 20px;
  justify-items: center;
  padding: 40px 80px;

  ${below.phone`
    padding: 20px 40px;
    display: block;
  `}

  h2 {
    grid-column: col-start / col-end;
    text-align: center;
    margin-bottom: 30px;
  }

  h3 {
    grid-column: col-start / col-end;
    text-align: center;
    font-size: 1.125rem !important;
    margin-top: 25px;
    margin-bottom: 5px;
  }

  a {
    grid-column: col-start / col-end;

    ${below.phone`
    display: flex;
    justify-content: center;
    margin-top: 10px;
  `}
  }

  button {
    margin-top: 30px;
    background: transparent !important;
    border: 2px solid ${colorPrimary} !important;
    color: ${colorPrimary} !important;
    i &:hover {
      background: ${colorPrimary} !important;
      color: #fff !important;
    }
  }
`;

const CheckboxSection = styled.section`
  background: #f7f7f7;
`;

const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1.125rem !important;
    font-weight: 600;
    margin: 0 0 0 10px;
  }
`;

export default Facilities;
