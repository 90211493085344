import React, { useEffect, useState } from 'react';
import {useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { Button } from '../elements/buttons';
import { Showcase } from '../elements/banners';
import { firestore } from '../firebase';
import Layout from '../containers/Layout';
import { collectIdsAndDocs } from '../utils';
import { below } from '../utilities/breakpoints';
import { colorPrimary } from '../utilities/colors';

const Gift = props => {
  const { eventId, giftId } = props.match.params;
  const [gift, setGift] = useState('');
  const [secImages, setImages] = useState('');
  const [values, setValues] = useState({});
  const [redirectToEvent, setRedirectToEvent] = useState(false);
  const history = useHistory();

  const handleInput = e => {
    e.persist();
    setValues(values => ({ ...values, [e.target.name]: e.target.value }));
  };


  const getGift = async () => {
    let unsubscribeFromGift;
    if (!gift) {
      unsubscribeFromGift = await firestore
        .doc(`/events/${eventId}/gifts/${giftId}`)
        .onSnapshot(snapshot => {
          const gift = collectIdsAndDocs(snapshot);
          setGift(gift);
          setImages(gift.secondaryImages);
        });
    }

    return () => unsubscribeFromGift();
  };

  useEffect(() => {
    getGift();
  }, [giftId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async e => {
    e.preventDefault();
    const giftRef = await firestore.doc(`/events/${eventId}/gifts/${giftId}`);
    await giftRef.update(values);
    toast.success('Gift Information Updated');
    setValues({});
    setRedirectToEvent(true);
  };

  if (redirectToEvent) {
     history.goBack();
  };



  const { giver, title, mainImage, secondaryImages, thankYouSent } = gift;
  const sent = thankYouSent ? 'yes' : 'no';

  return (
    <Layout>
      <Showcase>
        <h1>{title}</h1>
      </Showcase>
      <GiftContainer>
        <TitleWrapper>
        { secImages[1]  ?
          <CardSection3>
             <img src={mainImage} alt={title} width="300" />
             <img src={secImages[0]} alt="" width="300" />
             <img src={secImages[1]} alt="" width="300" />
          </CardSection3>
        : secImages[0] ?
          <CardSection2>
             <img src={mainImage} alt={title} width="300" />
             <img src={secImages[0]} alt="" width="300" />
          </CardSection2>
        : 
          <CardSection1>
             <img src={mainImage} alt={title} width="300" />
          </CardSection1>
        }
        </TitleWrapper>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              placeholder={title}
              value={values['title'] || title}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Giver</Form.Label>
            <Form.Control
              type="text"
              name="giver"
              placeholder={giver}
              value={values['giver'] || giver}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Thank You Sent</Form.Label>
            <Form.Control
              name="thankYouSent"
              value={values['thankYouSent'] || sent}
              onChange={handleInput}
              as="select"
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Form.Control>
          </Form.Group>
          <Button type="submit">Submit</Button>
        </Form>
      </GiftContainer>
    </Layout>
  );


};

const GiftContainer = styled.section`
  padding: 0 0 80px 0;

  form {
    max-width: 70vw;
    margin: 0 auto;
    text-align: left;
  }
`;

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;

  h1 {
    margin-bottom: 20px;
  }
`;

const CardSection1 = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 40px;
  justify-items: center;
  align-items: start;

  .h5 {
    color: ${colorPrimary};
  }

  .card {
    height: 500px;
    text-align: center;

    img {
      object-fit: cover;
    }
  }

`;

const CardSection2 = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 40px;
  place-items: center;

  .h5 {
    color: ${colorPrimary};
  }

  .card {
    height: 500px;
    text-align: center;

    img {
      object-fit: cover;
    }
  }

  ${below.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${below.phone`
    grid-template-columns: 1fr;
  `}

  .card__thank-you {
    color: ${colorPrimary};
  }
`;

const CardSection3 = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 40px;
  justify-items: center;
  align-items: start;

  .h5 {
    color: ${colorPrimary};
  }

  .card {
    height: 500px;
    text-align: center;

    img {
      object-fit: cover;
    }
  }

  ${below.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${below.phone`
    grid-template-columns: 1fr;
  `}

  .card__thank-you {
    color: ${colorPrimary};
  }
`;

export default Gift;
