import * as Btn from 'react-bootstrap/cjs/Button';
import styled from 'styled-components';
import { colorSecondary } from '../utilities/colors';

export const Button = styled(Btn)`
  background: ${props => props.background || colorSecondary} !important;
  color: ${props => props.color || '#fff'} !important;
  border: none !important;
  border-radius: 3px !important;
  font-size: 1.125rem !important;
  padding: 0.5rem 2rem !important;
  transition: all 0.2s !important;

  &:hover,
  &:active,
  &:focus {
    background: ${props => props.background || colorSecondary} !important;
    letter-spacing: 0.2px !important;
    color: ${props => props.color || '#fff'} !important;
  }
`;
