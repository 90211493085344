import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import GlobalStyle from '../global';

export default function Layout({ children }) {
  return (
    <>
      <LayoutContainer>
        <Header />
        <Main>{children}</Main>
        <Footer />
        <GlobalStyle />
      </LayoutContainer>
    </>
  );
}

const LayoutContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
