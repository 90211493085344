import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { firestore } from '../firebase';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CardElement, injectStripe } from 'react-stripe-elements';
import Form from 'react-bootstrap/Form';
import { Button } from '../elements/buttons';
import { UserContext } from '../providers/UserProvider';

const CheckoutForm = ({ stripe }) => {
  const [amount, setAmount] = useState(199);
  const [logState, setLogState] = useState(false);

  const currentUser = useContext(UserContext);

  const submit = async e => {
    e.preventDefault();

    try {
      const { token } = await stripe.createToken({ id: currentUser.user.id });

      const data = await firestore
        .collection(`facilities/${currentUser.user.uid}/charges`)
        .add({
          amount,
          source: token.id,
        });

      if (data) toast.success('Success!');
      setLogState(true);
      setAmount(199);
    } catch (error) {
      toast.error('Error! Please contact site administrator');
    }
  };

  if (logState) {
    return <Redirect to="/profile" />;
  }

  return (
    <CheckoutFormContainer className="checkout">
      <Form onSubmit={submit}>
        <FormGroup>
          <Form.Control type="checkbox" name="amount" checked />
          <Form.Label>New Subscription: $199</Form.Label>
        </FormGroup>
        <Form.Group>
          <Form.Label>Payment</Form.Label>
          <StripeCardElement />
        </Form.Group>
        <Button type="submit">Send</Button>
      </Form>
    </CheckoutFormContainer>
  );
};

const CheckoutFormContainer = styled.section`
  max-width: 900px;
  margin: 0 auto;
  padding-top: 40px;
`;

const StripeCardElement = styled(CardElement)`
  border: 1px solid #ced4da !important;
  padding: 10px;
  border-radius: 5px;
`;

const FormGroup = styled(Form.Group)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  input {
    margin-right: 10px;
    width: auto;
  }

  label {
    margin-bottom: 0;
  }
`;

export default injectStripe(CheckoutForm);
