import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import { firestore, storage } from '../firebase';
import { collectIdsAndDocs } from '../utils';
import Layout from '../containers/Layout';
import defaultImg from '../static/default.jpg';
import { Button } from '../elements/buttons';
import { Showcase } from '../elements/banners';

const MemoryBook = ({ eventCode }) => {
  const [event, setEvent] = useState('');
  const [values, setValues] = useState({});
  const [comments, setComments] = useState('');
  const [facility, setFacility] = useState('');
  const [img, setImg] = useState('');

  const handleInput = e => {
    e.persist();
    setValues(values => ({ ...values, [e.target.name]: e.target.value }));
  };

  const handleImage = e => {
    let currentImg;

    if (e.target.files) {
      currentImg = [...e.target.files][0];
    }

    setImg(currentImg);
  };

  const getFacility = async () => {
    if (event) {
      const facilityRef = await firestore.doc(`facilities/${event.facilityId}`);
      const facilityDoc = await facilityRef.get();
      const facility = facilityDoc.data();
      setFacility(facility);
    }
  };

  const getComments = () => {
    firestore.collection(`events/${event.id}/comments`).onSnapshot(snapshot => {
      const comments = snapshot.docs.map(collectIdsAndDocs);
      setComments(comments);
    });
  };

  const getEvent = () => {
    if (eventCode) {
    firestore
      .collection('events')
      .where('eventCode', '==', eventCode)
      .onSnapshot(snapshot => {
        const event = snapshot.docs.map(collectIdsAndDocs)[0];
        setEvent(event);
      });
    }
  };

  // Get events on component mount
  useEffect(() => {
    getEvent();
  }, [eventCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getFacility();
  }, [event]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get comments on component mount
  useEffect(() => {
    getComments();
  }, [event]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitMemory = async e => {
    e.preventDefault();

    try {
      const date = Date.now();
      const commentsRef = await firestore.collection(
        `events/${event.id}/comments`
      );
      const doc = await commentsRef.add({ ...values, date });

      if (img) {
        const url = await storage
          .ref()
          .child('comment-images')
          .child(doc.id)
          .put(img)
          .then(res => res.ref.getDownloadURL());

        doc.update({ image: url });
      }

      toast.success('Success!');
    } catch (e) {
      toast.error('Something went wrong. Please try again.');
    }
  };

  if (!event) return 'Loading...';

  const {
    title,
    image,
    dateOfBirth,
    dateOfDeath,
    description,
    eventFirstName,
    eventLastName,
  } = event;

  const { name, address, city, state } = facility;

  return (
    <Layout>
      <MemoryBookContainer>
        <Showcase>
          <h1>Memory Book</h1>
        </Showcase>
        <EventSection>
          <img src={image || defaultImg} alt={title} width="350" height="300" />
          <div>
            <h1>
              {eventFirstName} {eventLastName}
            </h1>
            <p>
              {dateOfBirth} - {dateOfDeath}
            </p>
            <p>{description}</p>
            <FuneralInfo>
              <p>{name}</p>
              <p>{address}</p>
              <p>
                {city}, {state}
              </p>
            </FuneralInfo>
          </div>
        </EventSection>
        <MemoryBookForm onSubmit={handleSubmitMemory}>
          <Form.Group>
            <Form.Label htmlFor="name">Name</Form.Label>
            <Form.Control
              name="name"
              id="name"
              type="text"
              placeholder="Enter name"
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="image">Image</Form.Label>
            <Form.Control
              onChange={handleImage}
              name="image"
              id="image"
              type="file"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="memory">Memory</Form.Label>
            <Form.Control
              name="memory"
              id="memory"
              as="textarea"
              rows="3"
              onChange={handleInput}
            />
          </Form.Group>
          <Button type="submit">Submit Memory</Button>
        </MemoryBookForm>
        <Comments>
          {comments &&
            comments
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .map(comment => (
                <Comment>
                  <CommentContent>
                    <div>
                      <p>{comment.memory}</p>
                    </div>
                    <div>
                      <p>by {comment.name}</p>
                      <p>
                        on <Moment format="MMM DD, YYYY">{comment.date}</Moment>
                        at
                        <Moment format="h:mm a">{comment.time}</Moment>}
                      </p>
                    </div>
                    <div>
                      <p>Photos: </p>
                      <img src={comment.image} alt={comment.name} width="100" />
                    </div>
                  </CommentContent>
                </Comment>
              ))}
        </Comments>
      </MemoryBookContainer>
    </Layout>
  );
};

const MemoryBookContainer = styled.div`
  padding: 0 0 80px 0;
`;

const EventSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80%;
  margin: 0 auto;

  img {
    margin-bottom: 20px;
    object-fit: cover;
  }
`;

const MemoryBookForm = styled(Form)`
  width: 60%;
  margin: 40px auto 0;
`;

const Comments = styled.div`
  width: 900px;
  margin: 0 auto;
  padding: 80px 0 0 0;
`;

const Comment = styled.div`
  background: #f7f7f7;
  border-radius: 5px;
  padding: 20px;

  > div > * {
    margin: 0;
  }

  :not(:first-child) {
    margin-top: 40px;
  }
`;

const CommentContent = styled.div`
  > div:first-child {
    p {
      font-size: 1.125rem !important;
      margin-bottom: 10px;
    }
  }

  > div:nth-child(2) {
    display: flex;
    align-items: baseline;

    svg {
      margin-right: 10px;
    }

    p {
      font-size: 0.85rem !important;
    }

    p:nth-child(n + 2):nth-child(-n + 3) {
      color: #9e9e9e;
      font-size: 0.85rem !important;
    }

    > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  div:nth-child(3) {
    margin-left: 10px;
  }
`;

const FuneralInfo = styled.div`
  > * {
    margin: 0;
  }
`;

export default MemoryBook;
