import React, { useContext,  useEffect, useState} from 'react';
import {useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { Button } from '../elements/buttons';
import { Showcase } from '../elements/banners';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';
import Layout from '../containers/Layout';
import { collectIdsAndDocs } from '../utils';


const EventInfo = props => {
  const { eventId } = props.match.params;
  const currentUser = useContext(UserContext);
  const [event, setEvent] = useState('');
  const [values, setValues] = useState({});
  const [redirectToEvents, setRedirectToEvents] = useState(false);
  const history = useHistory();

  const handleInput = e => {
    e.persist();
    if (e.target.name === 'isOpen') {
	    let updatedValue = e.target.value;

	    if (updatedValue === "false" || updatedValue === false) {
		e.target.value = false;
	    }
	    if (updatedValue === "true" || updatedValue === true) {
		e.target.value = true;
	    }
    }
    setValues(values => ({ ...values, [e.target.name]: e.target.value }));
  };

  const handleChange = (e) => {
    e.persist();
    let updatedValue = e.target.value;

    if (updatedValue === "false" || updatedValue === false) {
	e.target.value = false;
	setValues(values => ({ ...values, [e.target.name]:  false}));
    }
    if (updatedValue === "true" || updatedValue === true) {
	e.target.value = true;
	setValues(values => ({ ...values, [e.target.name]:  true}));
    }

  };

  const getEvent = () => {
    firestore
        .doc(`/events/${eventId}`)
        .onSnapshot(snapshot => {
          const event = collectIdsAndDocs(snapshot);
          setEvent(event);
        });
  };

  useEffect(getEvent, {});

  const handleSubmit = async e => {
    e.preventDefault();
    const eventRef = await firestore.doc(`/events/${eventId}`);
    await eventRef.update(values);
    toast.success('Event Information Updated');
    setValues({});
    setRedirectToEvents(true);
  };

  if (redirectToEvents) {
     history.goBack();
  };


  const {
    title,
    image,
    date,
    location,
    familyZip,
    familyPhone,
    familyModeOfContact,
    familyLastName,
    familyFirstName,
    familyAddress,
    primaryUserEmail,
    isOpen,
  } = event;

  return (
    <Layout>
      <Showcase>
        <h1>{title}</h1>
      </Showcase>
      <EventContainer>
        <TitleWrapper>
          <img src={image} alt={title} width="300" />
        </TitleWrapper>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              placeholder={title}
              value={values['title'] || title}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="text"
              name="date"
              placeholder={date}
              value={values['date'] || date}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              name="location"
              placeholder={location}
              value={values['location'] || location}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="familyLastName"
              placeholder={familyLastName}
              value={values['familyLastName'] || familyLastName}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="familyFirstName"
              placeholder={familyFirstName}
              value={values['familyFirstName'] || familyFirstName}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="familyAddress"
              placeholder={familyAddress}
              value={values['familyAddress'] || familyAddress}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type="text"
              name="familyZip"
              placeholder={familyZip}
              value={values['familyZip'] || familyZip}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="familyPhone"
              placeholder={familyPhone}
              value={values['familyPhone'] || familyPhone}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="primaryUserEmail"
              placeholder={primaryUserEmail}
              value={values['primaryUserEmail'] || primaryUserEmail}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Preferred Mode of Contact</Form.Label>
            <Form.Control
              name="familyModeOfContact"
              value={values['familyModeOfContact'] || familyModeOfContact}
              onChange={handleInput}
              as="select"
            >
              <option value="Phone">Phone</option>
              <option value="Email">Email</option>
            </Form.Control>
	  </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Event Status</Form.Label>
            <Form.Control
              name="isOpen"
              value={values['isOpen'] || isOpen}
              onChange={handleChange}
              as="select"
            >
              <option value="true">Open</option>
              <option value="false">Closed</option>
            </Form.Control>
	  </Form.Group>

	<ButtonContainer>
          <Button type="submit">Submit</Button>
	</ButtonContainer>
     	</Form>
       </EventContainer>
    </Layout>
  );
};

const EventContainer = styled.section`
  padding: 0 0 80px 0;

  form {
    max-width: 70vw;
    margin: 0 auto;
    text-align: left;
  }
`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  button:first-child {
    margin-right: 10px;
  }
`;

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;

  h1 {
    margin-bottom: 20px;
  }
`;

export default EventInfo;
