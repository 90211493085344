import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { UserContext } from '../providers/UserProvider';
import { signOut } from '../firebase';
import logo from '../static/logo.png';
import { below } from '../utilities/breakpoints';
import { colorPrimary } from '../utilities/colors';
const NavItem = Nav.Item;
const NavLink = Nav.Link;

export default function Header() {
  const currentUser = useContext(UserContext);
  const [redirectToHome, setRedirectToHome] = useState(false);

  const handleSignOut = e => {
    e.preventDefault();
    signOut();
    setRedirectToHome(true);
    currentUser.updateUser(null);
  };

  if (redirectToHome) {
    return <Redirect to='/' />;
  }

  return (
    <>
      <HeaderSection bg="dark" variant="dark" collapseOnSelect expand="lg">
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="Doorbadge" width="100" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle variant="light" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" activeKey="/home">
            {currentUser.user.uid ? (
              <>
                <Nav.Item>
                  <Link to="/events">Events</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/profile">Profile</Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={handleSignOut}>Sign Out</Nav.Link>
                </Nav.Item>
              </>
            ) : (
              <>
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle className="facilities-link" as={NavLink}>
                    Facilities
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Link className="nav-link" to="/facilities">
                      Why Doorbadge
                    </Link>
                    <Link className='nav-link' to='/faq'>
                      FAQs
                    </Link>
                    <Link className="nav-link" to="/sign-in">
                      Sign In
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
                <Nav.Item>
                  <Link to="/loved-ones">Loved Ones</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/memory-book">Memory Book</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/contact">Contact</Link>
                </Nav.Item>
                <Link to="/sign-up">
                  <Button className="header__sign-up" variant="outline-light">
                    Sign Up
                  </Button>
                </Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </HeaderSection>
    </>
  );
}

const HeaderSection = styled(Navbar)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: center;
  padding-left: 80px !important;
  padding-right: 80px !important;

  .navbar-nav {
    align-items: center;

    ${below.tablet`
      margin-top: 20px;
    `}
  }

  img {
    ${below.tablet`
      width: 75px;
    `}
  }

  a,
  a:hover {
    color: #fff !important;
    text-decoration: none;
    font-size: 1.125rem !important;
  }

  a,
  button {
    font-weight: 500;
  }

  button,
  a {
    margin-left: 20px;

    ${below.tablet`
      margin: 0;
      margin-top: 20px;
    `}
  }

  .navbar-toggler {
    width: fit-content;
    margin: 0 auto;
  }

  .header__sign-up:hover a {
    color: #111;
  }

  .dropdown-menu {
    padding: 10px 5px;
  }

  .dropdown-menu .nav-link {
    margin-bottom: 5px;
    white-space: nowrap;
  }

  .dropdown-menu .nav-link:not(.facilities-link) {
    color: #111 !important;
    font-size: 1rem !important;
    padding: 0 !important;
  }

  .dropdown-menu .nav-link:active {
    display: inline-block;
    width: 100%;
    color: ${colorPrimary} !important;
  }
`;
