import React from 'react';
import styled from 'styled-components';
import { Showcase } from '../elements/banners';
import Layout from '../containers/Layout';

const SignUp = () => {
  return (
    <Layout>
      <Showcase>
        <h1>Sign Up</h1>
      </Showcase>
      <Container>
        <div>
          <p>
            We at Doorbadge are glad you're interested in signing up for our service.
          </p>
          <p>
            Please fill out the information below and we will get back to shortly.
          </p>
	   <p>
            <strong>Phone:</strong> 877.835.2796
          </p>
	  <iframe title="Sign Up Form" src="https://docs.google.com/forms/d/1GNaXoaagkODCbBWgLreLfF296mGYWUYU4jTrmZTx81M/viewform?embedded=true" 
             width="640" height="1250" 
             frameborder="0" 
             marginheight="0" marginwidth="0" 
             onload = "loaded()">
               Loading�
          </iframe>
        </div>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  text-align: center;
  width: 80%;
  margin: 0 auto 0;
`;

export default SignUp;
