import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { below } from '../utilities/breakpoints';
import { colorPrimary } from '../utilities/colors';
import Button from 'react-bootstrap/Button';

import term from '../static/T&C20190801.pdf';

library.add(fab);

const Footer = () => {
  return (
    <FooterContainer>
      <div>
        <Link to='/privacy'>
          Privacy Policy
        </Link>
        {' | '}
        <a href={term} download>
          Terms And Conditions
        </a>
      </div>
      <Copyright>
        Copyright 2019 | Doorbadge | All Rights Reserved
      </Copyright>
      <IconContainer>
         <a href="https://www.facebook.com/DoorBadge-LLC-1309727162498918/"  target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook']} /></a>
         <a href="https://www.linkedin.com/in/doorbadge-app-b96ba2173/"  target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a>
      </IconContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 40px 0;

  ${below.phone`
    text-align: center;
  `}
`;

const IconContainer = styled.div`
  svg:first-child {
    margin-right: 10px;
  }

  svg {
    cursor: pointer;

    &:hover {
      color: ${colorPrimary};
    }
  }
`;

const Copyright = styled.div`
  margin: 10px;
`;

export default Footer;
