import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Layout from '../containers/Layout';
import { UserContext } from '../providers/UserProvider';
import { firestore } from '../firebase';
import { Redirect } from 'react-router-dom';
import FacilityProfile from '../components/FacilityProfile';
import FamilyProfile from '../components/FamilyProfile';
import { Showcase } from '../elements/banners';

const Profile = () => {
  const currentUser = useContext(UserContext);
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [values, setValues] = useState({});

  const handleInput = e => {
    e.persist();
    setValues(values => ({ ...values, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const { uid: userId } = currentUser.user;
    const facilityRef = firestore.doc(`facilities/${userId}`);
    await facilityRef.update({ uid: userId, ...values });
    setValues({});
    setRedirectToHome(true);
  };

  if (redirectToHome) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      <Showcase>
        <h1>{currentUser.user.name}</h1>
      </Showcase>
      <Container>
        {currentUser.user.type === 'facilities' ? (
          <FacilityProfile
            user={currentUser.user}
            handleInput={handleInput}
            handleSubmit={handleSubmit}
            values={values}
          />
        ) : (
          <FamilyProfile
            user={currentUser.user}
            handleInput={handleInput}
            handleSubmit={handleSubmit}
            values={values}
          />
        )}
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  width: 60%;
  margin: 0 auto 80px;
`;

export default Profile;
