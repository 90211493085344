import React from 'react';
import styled from 'styled-components';
import Layout from '../containers/Layout';
import { Showcase } from '../elements/banners';

const Contact = () => {
  return (
    <Layout>
      <Showcase>
        <h1>Contact Us</h1>
      </Showcase>
      <ContactContainer>
        <div>
          <p>
            We at Doorbadge are always here to help. Please contact us with any
            questions or concerns you may have.
          </p>
	   <p>
            <strong>Phone:</strong> 877.835.2796
          </p>
	  <p><iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdE-G1aeYCPUu12jupMZnXxj8GpEWeC9shmbvlALIg6IFdvkQ/viewform?embedded=true" width="640" height="808" frameborder="0" marginheight="0" marginwidth="0">Loading�</iframe>
	</p>	
        </div>
      </ContactContainer>
    </Layout>
  );
};

const ContactContainer = styled.section`
  text-align: center;
  width: 80%;
  margin: 0 auto 0;
`;

export default Contact;
