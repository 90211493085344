import styled from 'styled-components';
import heroImg from '../static/home-banner.jpg';
import { below } from '../utilities/breakpoints';

export const Showcase = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(35vh - 50px);
  justify-content: center;
  background: center center
      linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    center bottom url(${heroImg}) no-repeat;
  background-blend-mode: multiply;
  background-size: cover;
  margin-bottom: 80px;

  h1 {
    color: #fff;
    text-align: center;

    ${below.phone`
    padding: 0 10px;
  `}
  }
`;
