import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Moment from 'react-moment';
import { firestore } from '../firebase';
import { collectIdsAndDocs } from '../utils';
import Layout from '../containers/Layout';
import { Showcase } from '../elements/banners';
import defaultImg from '../static/default.jpg';
import mailbox_power from '../static/mailbox_power.png';
import { colorPrimary } from '../utilities/colors';
import Gifts from '../components/Gifts';

const Event = props => {
  const { eventCode } = props.match.params;
  const [event, setEvent] = useState('');
  const [gifts, setGifts] = useState('');

  const getEvent = () => {
    firestore
      .collection('events')
      .where('eventCode', '==', eventCode)
      .onSnapshot(snapshot => {
        const event = snapshot.docs.map(collectIdsAndDocs)[0];
        setEvent(event);
      });
  };

  const getGifts = async () => {
    const unsubscribeFromGifts = await firestore
      .collection(`events/${event.id}/gifts`)
      .onSnapshot(snapshot => {
        const gifts = snapshot.docs.map(collectIdsAndDocs);
        setGifts(gifts);
      });

    return () => {
      setGifts('');
      unsubscribeFromGifts();
    };
  };

  useEffect(getEvent, {});

  useEffect(() => {
    getGifts();
  }, [event]); // eslint-disable-line

  const {
    title,
    image,
    date,
    location,
    familyZip,
    familyPhone,
    familyModeOfContact,
    familyLastName,
    familyFirstName,
    familyAddress,
    primaryUserEmail,
  } = event;

  return (
    <Layout>
      <Showcase>
        <h1>{title}</h1>
      </Showcase>
      <EventContainer>
        <EventContent>
          <img src={image || defaultImg} alt={title} width="350" height="300" />
          <div>
            <h2>
              {familyFirstName} {familyLastName}
            </h2>
            <p>
              <Moment format="MMM DD, YYYY">{date}</Moment>
            </p>
            <p>{location}</p>
            <p>
              {familyAddress}, {familyZip}
            </p>
            <p>
              {familyPhone}{' '}
              {familyModeOfContact === 'Phone' ? <sup>*</sup> : ''}
            </p>
            <p>
              {primaryUserEmail}{' '}
              {familyModeOfContact === 'Email' ? <sup>*</sup> : ''}
            </p>
          </div>
                  <Link to={`/eventInfo/${event.id}`}>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </Link>
        </EventContent>
        <Gifts gifts={gifts} eventId={event.id} />
        <AdditionalInfo>
          <small>* = Preferred mode of contact</small>
        </AdditionalInfo>
        <IconContainer>
           <p>

           </p>
	   <p>
	       Click on the link below to send Thank You notes using our preferred partner:

           </p>
           <a href="https://www.mailboxpower.com/index.cfm/home/index"  target="_blank" rel="noopener noreferrer"><img src={mailbox_power} alt="Mailbox Power" width="100" /></a>
        </IconContainer>
      </EventContainer>
    </Layout>
  );
};

const EventContainer = styled.section`
  padding: 0 0 80px 0;
  width: 80%;
  margin: 0 auto;
  text-align: center;

  h2 {
    margin: 20px 0;
  }

  img {
    margin-bottom: 20px;
    object-fit: cover;
  }
`;

const AdditionalInfo = styled.div`
  margin-top: 40px;
`;

const EventContent = styled.div`
  *:not(h2) {
    margin-bottom: 5px;
  }

  p:first-of-type {
    margin-bottom: 20px;
    font-weight: 500;
  }
`;

const IconContainer = styled.div`
  svg:first-child {
    margin-right: 10px;
  }

  svg {
    cursor: pointer;

    &:hover {
      color: ${colorPrimary};
    }
  }
`;

export default Event;
