import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { toast } from 'react-toastify';

// Database configuration
var firebaseConfig = {
  apiKey: 'AIzaSyB8h4gi3M1mdPCAnveRFo_JWOakFTB1PHA',
  authDomain: 'doorbadge-a8540.firebaseapp.com',
  databaseURL: 'https://doorbadge-a8540.firebaseio.com',
  projectId: 'doorbadge-a8540',
  storageBucket: 'doorbadge-a8540.appspot.com',
  messagingSenderId: '820538480065',
  appId: '1:820538480065:web:e777fe9173061fb0',
};


// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Initialize Firebase Auth
export const auth = firebase.auth();

// initialize Firestore
export const firestore = firebase.firestore();

// Initialize Storage
export const storage = firebase.storage();

// Set up Google Authentication
export const google = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => auth.signInWithPopup(google);

// Set up Facebook Authentication
export const facebook = new firebase.auth.FacebookAuthProvider();
export const signInWithFacebook = () => auth.signInWithPopup(facebook);

// Sign out method
export const signOut = e => {
  auth.signOut();
  toast.info('Signed out.');
  localStorage.removeItem('isAuth');
  localStorage.removeItem('type');
};

// Password reset method
export const resetPassword = email => {
  auth.sendPasswordResetEmail(email);
};

export const createUserProfileDocument = async (user, type) => {
  if (!user) return;

  // Get a reference to the user's profile
  const userRef = firestore.doc(`${type}/${user.uid}`);

  // Go and fetch document from that location
  const snapshot = await userRef.get();

  // If snapshot does not exist, let's create one
  if (!snapshot.exists) {
    const { email } = user;
    // const createdAt = new Date();
    try {
      await userRef.set({
        events: [],
        email,
      });
    } catch (error) {
      console.error(`Error creating user`, error);
    }
  }

  // Get user document out of database
  return getUserDocument(user.uid, type);
};

export const getUserDocument = (uid, type) => {
  if (!uid) return null;

  try {
    return firestore.collection(type).doc(uid);
  } catch (error) {
    console.error('Error fetching user', error.message);
  }
};

export default firebase;
