import styled from 'styled-components';
import { below } from '../utilities/breakpoints';

import heroImg from '../static/home-banner.jpg';

export const Hero = styled.div`
  align-items: center;
  flex-direction: column;
  // height: calc(70vh - 50px);  
  height: ${props => (props.small ? '400px' : '600px')}
  
  ${below.phone`
    height: ${props => props.small && '600px'}
  `}

  justify-content: center;
  background: center center linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    center bottom url(${heroImg}) no-repeat;
  background-blend-mode: multiply;
  background-size: cover;

  display: table;
  width: 100%;


`;

export const HeroContent = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 20px;

  h1,
  h2 {
    color: #fff;
  }

  h1 {
    font-size: ${props => (props.small ? '3.052rem !important' : '5.052rem !important')}
    text-transform: uppercase;
    text-align: center;

    ${below.phone`
      font-size: 3.5rem !important;
      font-size: ${props => (props.small ? '2.5rem !important' : '3.052rem !important')}
    `}
  }

  h2 {
    margin-bottom: 20px;
    font-weight: 300;
    text-align: center;
  }
`;
