import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from '../firebase';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { Button } from '../elements/buttons';
import { Showcase } from '../elements/banners';
import { Container } from '../elements/containers';
import Layout from '../containers/Layout';

const LovedOnes = ({ onSubmit }) => {
  const [values, setValues] = useState({});
  const [user, setUser] = useState({}); // eslint-disable-line no-unused-vars
  const [logState, setLogState] = useState(false);

  const handleInput = e => {
    e.persist();
    setValues(values => ({ ...values, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    await localStorage.setItem('type', 'users');
    const { email, password } = values;
    try {
      const data = await auth.signInWithEmailAndPassword(email, password);
      localStorage.setItem('isAuth', true);
      setUser({ id: data.uid, email: data.email });
      toast.success('Successfully Signed In');
      setLogState(true);
    } catch (error) {
      toast.error('Invalid credentials!');
    }
  };

  if (logState) {
    return <Redirect to="/events" />;
  }

  return (
    <Layout>
      <Showcase>
        <h1>Family Sign In</h1>
      </Showcase>
      <Container>
        <SignUpContent>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                onChange={handleInput}
                type="email"
                name="email"
                placeholder="Enter email"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Password</Form.Label>
              <Form.Control
                onChange={handleInput}
                type="password"
                name="password"
                placeholder="Enter password"
              />
            </Form.Group>
            <ButtonContainer>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </ButtonContainer>
          </Form>
        </SignUpContent>
      </Container>
    </Layout>
  );
};

const SignUpContent = styled.div`
  width: 60%;
  margin: 0 auto;

  h1 {
    margin-bottom: 20px;
  }
`;

const ButtonContainer = styled.div`
  button:not(:last-child) {
    margin-right: 5px;
  }
`;

export default LovedOnes;
