import { createGlobalStyle } from 'styled-components';
import { normalize, lighten } from 'polished';
import { below } from './utilities/breakpoints';
import { colorPrimary } from './utilities/colors';

const GlobalStyle = createGlobalStyle`
  ${normalize()}
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;

    ${below.tablet`
      font-size: 14px;
      `}
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    scroll-behavior: inherit !important;
    font-family: 'Montserrat', sans-serif !important;
  }

  h1 {
    font-size: 3.052rem !important;
    color: ${colorPrimary};
    margin: 0;

    ${below.phone`
      font-size: 2.5rem !important;
    `}
  }

  h2 {
    font-size: 2.441rem !important;
    color: ${colorPrimary}
  }

  h3 {
    font-size: 1.953rem !important;
    color: ${colorPrimary}
  }

  h4 {
    font-size: 1.563rem !important;
    color: ${colorPrimary}
  }

  h5 {
    font-size: 1.25rem !important;
    color: ${colorPrimary}
  }

  p,
  a,
  li,
  span {
    font-size: 1rem !important;
  }

  a {
    text-decoration: none;
    color: ${colorPrimary} !important;

    &:hover {
      color: ${lighten(0.1, colorPrimary)} !important;
      text-decoration: none !important;
    }
  }
`;

export default GlobalStyle;
