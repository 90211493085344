import React from 'react';
import styled from 'styled-components';
import { Link } from '@react-navigation/native';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from '../components/CheckoutForm';
import Layout from '../containers/Layout';
import { Showcase } from '../elements/banners';
import { below } from '../utilities/breakpoints';

const Checkout = () => {
  return (
    <Layout>
      <Showcase>
        <h1>Checkout</h1>
      </Showcase>
      <CheckoutContainer>
        <StripeProvider apiKey="pk_test_1RFcKaMgk9eUZ3RqT9mhu8Gw">
          <div className="checkout-form-wrap">
            <Elements>
              <CheckoutForm />
            </Elements>
          </div>
        </StripeProvider>
        <SignUpCta>
          <p>
            <Link to="/sign-up">Sign up for an account!</Link>
          </p>
          <p>
            <Link to="/reset-password"> Forgot your password?</Link>
          </p>
        </SignUpCta>
      </CheckoutContainer>
    </Layout>
  );
};

const CheckoutContainer = styled.div`
  padding: 0 0 80px 0;

  ${below.phone`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}

  .checkout-form-wrap {
    ${below.phone`
      width: 75%;
    `}
  }

  .checkout {
    padding-top: 0;
  }
`;

const SignUpCta = styled.div`
  padding-top: 40px;
  max-width: 900px;
  margin: 0 auto;
`;

export default Checkout;
