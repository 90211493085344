import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { below } from '../utilities/breakpoints';
import { colorPrimary } from '../utilities/colors';
import { Button } from '../elements/buttons';
import { ButtonContainer } from '../elements/containers';
import heroImg from '../static/home-banner.jpg';
import flowers from '../static/flowers.jpg';
import couple from '../static/couple.jpg';
import guests from '../static/guests.jpg';
import Layout from '../containers/Layout';
import video from '../static/video.mp4';
import thumbnail from '../static/thumbnail.png';

const Index = () => {

  return (
    <Layout>
      <Hero>
        <h1>Doorbadge</h1>
        <h2>Thank You Reimagined</h2>
        <ButtonContainer>
          <Link to="/sign-in">
            <Button background="#fff" color="#111">Facilities Log In</Button>
          </Link>
          <Link to="/loved-ones">
            <Button background="#fff" color="#111">Loved Ones Log In</Button>
          </Link>
        </ButtonContainer>
      </Hero>
      <About>
        <div>
	<video width="100%" height="auto" controls preload="metadata" poster={thumbnail}>
  	<source src={video} type="video/mp4">
	</source>
	</video>
	</div>
        <div>
          <h2>Our Story</h2>
          <p>
            DoorBadge was created after the unexpected death of a family member.
            The shoebox full of sympathy cards, and the mountain of flowers,
            were amazing and yet overwhelming at the same time. DoorBadge
            provides the platform to bring all of this together in a clean, easy
            to use framework that allows families to have more time together
            and less time wading through the monumental task of thanking
            everyone for their love and support.
          </p>
        </div>
      </About>
      <CTA>
        <p>
          Contact us to learn more about how Doorbadge can help you serve your
          families.
        </p>
        <ButtonContainer>
          <Link to="/contact">
            <Button>Contact</Button>
          </Link>
          <Link to="/facilities">
            <Button>Why DoorBadge</Button>
          </Link>
        </ButtonContainer>
      </CTA>
      <Product>
        <h2>Our Platform</h2>
        <ProductContent>
          <div>
            <img src={flowers} alt="Flowers" width="300" height="300" />
            <h3>Facilities</h3>
            <p>
              Understanding your clients, and providing the perfect solution, is
              the best way to make the grieving process a little easier for
              them. The reputation of your facility depends on it. That is why
              DoorBadge seeks to help you, help your clients, by providing a
              solution that extends beyond your doorframe.
            </p>
          </div>
          <div>
            <img src={couple} alt="Couple" width="300" height="300" />
            <h3>Loved Ones</h3>
            <p>
              Here at DoorBadge, we understand the chaos that can come after the
              whirlwind of a loved one's funeral or life celebration. That chaos
              is what led us to create DoorBadge. Our goal is to ensure you have
              the easiest time possible showing your appreciation for those that
              loved your special someone. The grieving process is hard enough,
              the thank you side should be easy.
            </p>
          </div>
          <div>
            <img src={guests} alt="Candle" width="300" height="300" />
            <h3>Guests</h3>
            <p>
              Thank you for your love and support. We appreciate you. Please add
              your special story to the memory book. Our family's would love to
              hear about your personal relationship.
            </p>
          </div>
        </ProductContent>
      </Product>
      <CTA>
        <p>Ready To Get Started?</p>
        <ButtonContainer>
          <Link to="/sign-up">
            <Button>Start</Button>
          </Link>
        </ButtonContainer>
      </CTA>
    </Layout>
  );
};

const Hero = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(70vh - 50px);
  justify-content: center;
  background: center center
      linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    center bottom url(${heroImg}) no-repeat;
  background-blend-mode: multiply;
  background-size: cover;

  h1,
  h2 {
    color: #fff;
  }

  h1 {
    font-size: 5.052rem !important;
    text-transform: uppercase;
    text-align: center;

    ${below.phone`
      font-size: 3.5rem !important;
  `}
  }

  h2 {
    margin-bottom: 20px;
    font-weight: 300;
    text-align: center;
  }
`;

const About = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${below.tablet`
    grid-template-columns: 1fr;
    justify-items: center;
  `}

  iframe {
    width: 100%;
  }

  div {
    padding: 80px;

    ${below.tablet`
    padding: 20px;
  `}
  }

  div:last-child {
    text-align: center;
  }

  h2 {
    color: ${colorPrimary};
    text-transform: uppercase;

    ${below.tablet`
      width: fit-content;
      margin: 0 auto;
    `}

    &:after {
      background: ${colorPrimary};
      content: '';
      display: block;
      height: 2px;
      margin: 10px 0 20px;
      width: 100%;
    }
  }

  p {
    line-height: 1.5;
    font-size: 1.125rem !important;
  }
`;

const CTA = styled.section`
  background: #f7f7f7;
  padding: 40px 0;
  text-align: center;

  ${below.tablet`
    p {
      width: 90%;
      margin: 0 auto 20px;
  }
  `}

  p {
    font-weight: 500;
    font-size: 1.5rem !important;
  }
`;

const Product = styled.section`
  padding: 80px 0;
  width: 80%;
  margin: 0 auto;

  h2 {
    text-align: center;
    margin: 0 0 60px;
    text-transform: uppercase;
  }

  h3 {
    font-size: 1.5rem !important;
  }
`;

const ProductContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  text-align: center;

  ${below.tablet`
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
    grid-column-gap: 0;
    width: 80%;
    margin: 0 auto;
    `}

  img {
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 40px;

    ${below.tablet`
      width: 250px;
      height: 250px;
      `}
  }

  p {
    line-height: 1.7;
  }
`;


export default Index;
