import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { firestore } from '../firebase';
import { collectIdsAndDocs } from '../utils';
import { Button } from '../elements/buttons';
import { Showcase } from '../elements/banners';
import { Loader } from '../elements/loaders';
import { UserContext } from '../providers/UserProvider';
import Layout from '../containers/Layout';
import defaultImg from '../static/default.jpg';
import { below } from '../utilities/breakpoints';
import { colorPrimary } from '../utilities/colors';
import Lightbox from '../components/Lightbox';

const Events = () => {
  const [events, setEvents] = useState('');
  const [images, setImages] = useState([]);
  const [lightbox, setLightbox] = useState(false);
  const [open, setOpen] = useState(true);
  const currentUser = useContext(UserContext);

  const getEvents = () => {
    if (currentUser.user.events) {
      firestore.collection('events').onSnapshot(snapshot => {
        const allEvents = snapshot.docs.map(collectIdsAndDocs);
        const events = allEvents.filter(event =>
          currentUser.user.events.includes(event.id)
        );
        setEvents(events);
      });
    }
  };

  const handleSort = (e, eventType) => {
    e.preventDefault();

    const type = eventType === 'open' ? true : false;
    setOpen(type);
  };

  const displayLightbox = mainImage => {
    const imgArray = [{ src: mainImage || defaultImg, alt: '' }];

    setLightbox(!lightbox);
    setImages(imgArray);
  };

  useEffect(getEvents, [currentUser]);


  return (
    <Layout>
      <Showcase>
        <h1>Events</h1>
      </Showcase>
      <Container>
        {currentUser.user.type === 'facilities' ? 
	   <Container>
        	<SortContainer>
	          <Button onClick={e => handleSort(e, 'open')}>Open Events</Button>
        	  <Button onClick={e => handleSort(e, 'closed')}>Closed Events</Button>
	        </SortContainer>
        	<CardSection>
	          {events &&
        	    events
	              .sort((a, b) => new Date(a.date) - new Date(b.date))
        	      .filter(event => event.date !== '99999999')
	              .filter(event => {
        	        return event.isOpen === open;
	              })
        	      .map(event => {
	                const {
                	  eventFirstName,
        	          eventLastName,
	                  image,
                	  date,
        	          eventCode,
	                } = event;
                	return (
        	          <Card style={{ width: '18rem' }} key={eventCode}>
			      <Link to={`/event/${eventCode}`}>
 			      <Card.Img
        	              variant="top"
	                      src={image || defaultImg}
                	      height="300"
        	              width="300"
                     
	                    />
			      </Link>
        	            <Card.Body>
	                      <Card.Title>
                	        {eventFirstName} {eventLastName}
        	              </Card.Title>
	                      <Card.Text>
                	        {date === '99999999' ? (
        	                  'TBA'
	                        ) : (
                        	  <Moment format="MMM DD, YYYY">{date}</Moment>
                	        )}
        	              </Card.Text>
	                      <Card.Text>Event Code: {eventCode}</Card.Text>
                   		<Link to={`/event/${eventCode}`}>
                	        <Button variant="primary">View Event</Button>
        	              </Link>
	                    </Card.Body>
                	  </Card>
        	        );
	              })}
	          {events &&
        	    events
	              .filter(event => event.date === '99999999')
	              .filter(event => {
        	        return event.isOpen === open;
	              })
        	      .map(event => {
	                const {
                	  eventFirstName,
        	          eventLastName,
	                  image,
                	  date,
        	          eventCode,
	                } = event;
                	return (
        	          <Card style={{ width: '18rem' }} key={eventCode}>
	                    <Card.Img
                	      variant="top"
        	              src={image || defaultImg}
	                      height="300"
                	      width="300"
        	              onClick={() => displayLightbox(image)}
	                    />

        	            <Card.Body>
	                      <Card.Title>
                	        {eventFirstName} {eventLastName}
        	              </Card.Title>
	                      <Card.Text>
                        	{date === '99999999' ? 'TBA' : date}
                	      </Card.Text>
        	              <Card.Text>Event Code: {eventCode}</Card.Text>
	                      <Link to={`/event/${eventCode}`}>
                        	<Button variant="primary">View Event</Button>
                	      </Link>
        	            </Card.Body>
	                  </Card>
                	);
        	      })}
	        </CardSection>
	   </Container>
	    : (
		<CardSection>
	          {events &&
        	    events
	              .sort((a, b) => new Date(a.date) - new Date(b.date))
        	      .filter(event => event.date !== '99999999')
        	      .map(event => {
	                const {
                	  eventFirstName,
        	          eventLastName,
	                  image,
                	  date,
        	          eventCode,
	                } = event;
                	return (
        	          <Card style={{ width: '18rem' }} key={eventCode}>
			      <Link to={`/event/${eventCode}`}>
 			      <Card.Img
        	              variant="top"
	                      src={image || defaultImg}
                	      height="300"
        	              width="300"
                     
	                    />
			      </Link>
        	            <Card.Body>
	                      <Card.Title>
                	        {eventFirstName} {eventLastName}
        	              </Card.Title>
	                      <Card.Text>
                	        {date === '99999999' ? (
        	                  'TBA'
	                        ) : (
                        	  <Moment format="MMM DD, YYYY">{date}</Moment>
                	        )}
        	              </Card.Text>
	                      <Card.Text>Event Code: {eventCode}</Card.Text>
                   		<Link to={`/event/${eventCode}`}>
                	        <Button variant="primary">View Event</Button>
        	              </Link>
	                    </Card.Body>
                	  </Card>
        	        );
	              })}
		</CardSection>
       )}
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  padding: 0 0 80px 0;

  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
`;

const CardSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: start;
  width: 80%;
  margin: 0 auto;
  grid-row-gap: 40px;

  .card-img-top {
    object-fit: cover !important;
  }

  .h5 {
    color: ${colorPrimary};
  }

  .card {
    text-align: center;
  }

  ${below.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${below.phone`
    grid-template-columns: 1fr;
  `}
`;

const SortContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  button:first-child {
    margin-right: 10px;
  }
`;

export default Events;
