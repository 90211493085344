import React from 'react';

const FacilityProfile = ({ user }) => {
  const { email } = user;

  return (
    <p style={{ paddingTop: 20 }}>
      <strong>Email:</strong> {email}
    </p>
  );
};

export default FacilityProfile;
