import React from 'react';
import styled from 'styled-components';
import Layout from '../containers/Layout';
import { Showcase } from '../elements/banners';


const PrivacyPolicy = () => {
  return (
    <Layout>
        <Showcase>
          <h1>ONLINE PRIVACY POLICY AGREEMENT</h1>
          </Showcase>
    

      <PrivacyContainer>
        <p>
          Doorbadge, (Doorbadge), is committed to keeping any and all personal
          information collected of those individuals that visit our website and
          make use of our online facilities and services accurate, confidential,
          secure and private. Our privacy policy has been designed and created
          to ensure those affiliated with Doorbadge of our commitment and
          realization of our obligation not only to meet but to exceed most
          existing privacy standards.
        </p>

        <br />
        <p>
          THEREFORE, this Privacy Policy Agreement shall apply to Doorbadge and
          any subsidiary company listed below , and thus it shall govern any and
          all data collection and usage thereof. Through the use of &nbsp;
          <a href='www.doorbadge.com'>www.doorbadge.com</a> &nbsp; you are
          herein consenting to the following data procedures expressed within
          this agreement.
        </p>

        <br />
        <p>Subsidiary Company:</p>

        <br />
        <p>BB Tech</p>

        <br />
        <p>Collection of Information</p>
        <p>This website collects various types of information, such as:</p>
        <p>
          Voluntarily provided information which may include your name, address,
          email address, billing and/or credit card information etc., which may
          be used when you purchase products and/or services and to deliver the
          services you have requested.
        </p>
        <p>
          Information automatically collected when visiting our website, which
          may include cookies, third party tracking technologies and server
          logs.
        </p>
        <p>
          Please rest assured that this site shall only collect personal
          information that you knowingly and willingly provide by way of
          surveys, completed membership forms, and emails. It is the intent of
          this site to use personal information only for the purpose for which
          it was requested and any additional uses specifically provided on this
          site.
        </p>

        <br />
        <p>
          We may also gather information about the type of browser you are
          using, IP address or type of operating system to assist us in
          providing and maintaining superior quality service.
        </p>

        <br />
        <p>
          It is highly recommended and suggested that you review the privacy
          policies and statements of any website you choose to use or frequent
          as a means to better understand the way in which other websites
          garner, make use of and share information collected.
        </p>

        <br />
        <p>Use of Information Collected</p>
        <p>
          Doorbadge may collect and may make use of personal information to
          assist in the operation of our website and to ensure delivery of the
          services you need and request. At times, we may find it necessary to
          use personally identifiable information as a means to keep you
          informed of other possible products and/or services that may be
          available to you from www.doorbadge.com and our subsidiaries .
          Doorbadge and our subsidiaries may also be in contact with you with
          regards to completing surveys and/or research questionnaires related
          to your opinion of current or potential future services that may be
          offered.
        </p>

        <br />
        <p>
          Doorbadge does not now, nor will it in the future, sell, rent or lease
          any of our customer lists and/or names to any third parties. Doorbadge
          may deem it necessary to follow websites and/or pages that our users
          may frequent in an effort to gleam what types of services and/or
          products may be the most popular to customers or the general public.
        </p>

        <br />
        <p>
          Doorbadge may disclose your personal information, without prior notice
          to you, only if required to do so in accordance with applicable laws
          and/or in a good faith belief that such action is deemed necessary or
          is required in an effort to:
        </p>

        <br />
        <p>
          Remain in conformance with any decrees, laws and/or statutes or in an
          effort to comply with any process which may be served upon Doorbadge
          and/or our website;
        </p>

        <p>
          Maintain, safeguard and/or preserve all the rights and/or property of
          Doorbadge; and
        </p>
        <p>
          Perform under demanding conditions in an effort to safeguard the
          personal safety of users of &nbsp;
          <a href='www.doorbadge.com'>www.doorbadge.com</a> &nbsp; and/or the
          general public
        </p>
        <p>Children Under Age of 13</p>

        <p>
          Doorbadge does not knowingly collect personal identifiable information
          from children under the age of thirteen (13) without verifiable
          parental consent. If it is determined that such information has been
          inadvertently collected on anyone under the age of thirteen (13), we
          shall immediately take the necessary steps to ensure that such
          information is deleted from our system's database. Anyone under the
          age of thirteen (13) must seek and obtain parent or guardian
          permission to use this website.
        </p>

        <br />
        <p>Unsubscribe or Opt-Out</p>
        <p>
          All users and/or visitors to our website have the option to
          discontinue receiving communication from us and/or reserve the right
          to discontinue receiving communications by way of email or
          newsletters. To discontinue or unsubscribe to our website please send
          an email that you wish to unsubscribe to brian.vires@doorbadge.com. If
          you wish to unsubscribe or opt-out from any third party websites, you
          must go to that specific website to unsubscribe and/or opt-out.
        </p>

        <br />
        <p>Links to Other Web Sites</p>
        <p>
          Our website does contain links to affiliate and other websites.
          Doorbadge does not claim nor accept responsibility for any privacy
          policies, practices and/or procedures of other such websites.
          Therefore, we encourage all users and visitors to be aware when they
          leave our website and to read the privacy statements of each and every
          website that collects personally identifiable information. The
          aforementioned Privacy Policy Agreement applies only and solely to the
          information collected by our website.
        </p>

        <br />
        <p>Security</p>
        <p>
          Doorbadge shall endeavor and shall take every precaution to maintain
          adequate physical, procedural and technical security with respect to
          our offices and information storage facilities so as to prevent any
          loss, misuse, unauthorized access, disclosure or modification of the
          user's personal information under our control.
        </p>

        <p>
          The company also uses Secure Socket Layer (SSL) for authentication and
          private communications in an effort to build users' trust and
          confidence in the internet and website use by providing simple and
          secure access and communication of credit card and personal
          information.
        </p>

        <br />
        <p>Changes to Privacy Policy Agreement</p>
        <p>
          Doorbadge reserves the right to update and/or change the terms of our
          privacy policy, and as such we will post those change to our website
          homepage at www.doorbadge.com, so that our users and/or visitors are
          always aware of the type of information we collect, how it will be
          used, and under what circumstances, if any, we may disclose such
          information. If at any point in time Doorbadge decides to make use of
          any personally identifiable information on file, in a manner vastly
          different from that which was stated when this information was
          initially collected, the user or users shall be promptly notified by
          email. Users at that time shall have the option as to whether or not
          to permit the use of their information in this separate manner.
        </p>

        <br />
        <p>Acceptance of Terms</p>
        <p>
          Through the use of this website, you are hereby accepting the terms
          and conditions stipulated within the aforementioned Privacy Policy
          Agreement. If you are not in agreement with our terms and conditions,
          then you should refrain from further use of our sites. In addition,
          your continued use of our website following the posting of any updates
          or changes to our terms and conditions shall mean that you are in
          agreement and acceptance of such changes.
        </p>

        <br />
        <p>How to Contact Us</p>
        <p>
          If you have any questions or concerns regarding the Privacy Policy
          Agreement related to our website, please feel free to contact us at
          the following email, telephone number or mailing address.
        </p>

        <br />

        <p>
          <a href='mailto:info@doorbadge.com'>Email:info@doorbadge.com</a>
        </p>

        <p>Telephone Number: 877.835.2796</p>

        <p>Mailing Address:</p>
        <p>Doorbadge </p>
        <p>4751 Trousdale Dr #10 </p>
        <p>Nashville, TN 37220</p>
      </PrivacyContainer>
    </Layout>
  );
};

const PrivacyContainer = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 50px auto 50px auto;
  padding: 20px;

  a {
    color: black !important;
  }
`;

export default PrivacyPolicy;
