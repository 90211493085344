import React, { Component, createContext } from 'react';
import { auth, createUserProfileDocument } from '../firebase';

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  state = {
    user: { user: null }
  };

  unsubscribeFromAuth = null;

  componentDidMount() {
    /*
     * @description Subscribe to database updates and run callback every time database
     * changes.
     * @return method that allows you to unsubscribe
     */

    this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userType = localStorage.getItem('type');

        // Get reference to the user in the database
        const userRef = await createUserProfileDocument(userAuth, userType);

        // Subscribe to changes in user document
        // Update state with latest data
        userRef.onSnapshot(snapshot => {
          this.setState({ user: { uid: snapshot.id, ...snapshot.data(), type: userType } });
        });
      }
      this.setState({ userAuth });
    });
  }

  componentWillUnmount() {
    // Unsubscribe to firebase listener
    this.unsubscribeFromAuth();
  }

  updateUser = value => {
    this.setState(value => ({ user: value }));
  };

  render() {
    const { user } = this.state;
    const { children } = this.props;

    return <UserContext.Provider value={{ user, updateUser: this.updateUser }}>{children}</UserContext.Provider>;
  }
}

export default UserProvider;
