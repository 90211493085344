import React from 'react';

import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, path, ...rest }) => {
  const isAuth = localStorage.getItem('isAuth');
  return isAuth ? (
    <Route {...rest} component={Component} path={path} />
  ) : (
    <Redirect noThrow from={`/${window.location.pathname}`} to={'/sign-in'} />
  );
};

export default ProtectedRoute;
