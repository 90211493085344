import React from 'react';
import Collapsible from 'react-collapsible';

import './style.css';

const FAQ = ({ question, answer }) => {
  return (
    <Collapsible trigger={question}>
      <p>{answer}</p>
    </Collapsible>
  );
};

export default FAQ;
