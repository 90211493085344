import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { resetPassword } from '../firebase';
import Layout from '../containers/Layout';
import { Button } from '../elements/buttons';
import { Showcase } from '../elements/banners';
import { Container } from '../elements/containers';

const ResetPassword = () => {
  const [email, setEmail] = useState('');

  const handleInput = e => setEmail(e.target.value);

  const handleSubmit = e => {
    e.preventDefault();

    try {
      resetPassword(email);
      toast.success('Reset email sent!');
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  return (
    <Layout>
      <Showcase>
        <h1>Reset Password</h1>
      </Showcase>
      <Container>
        <ResetPasswordContent>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                onChange={handleInput}
                type="email"
                name="email"
                placeholder="Enter email"
              />
            </Form.Group>
            <Button type="submit">Reset Password</Button>
          </Form>
        </ResetPasswordContent>
      </Container>
    </Layout>
  );
};

const ResetPasswordContent = styled.div`
  width: 60%;
  margin: 0 auto;

  h1 {
    margin-bottom: 20px;
  }
`;

export default ResetPassword;
